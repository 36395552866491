@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

.home-buttons .ant-btn {
  background: rgba(82, 94, 117, 0.1);
  color: #1e232cfa;
  height: 36px;
  border-radius: 6px;
  padding: 5px 10px 5px 10px;
  font-size: 16px;
  font-weight: 500;
}

.home-buttons .ant-btn-primary {
  background: #3594ff !important;
  color: #ffffff !important;
}

.taskset-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.todo-radio .ant-radio-inner {
  border: 1.8px solid #1e232cde !important;
}
.taskset-icons {
  display: flex;
  gap: 30px;
  color: #36454f;
  font-size: 22px;
  cursor: pointer;
}

.priority-flag p {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.priority-option .ant-select-item-option-content {
  display: flex;
  align-items: center;
  gap: 10px;
}
.priority-select .ant-select-selection-item {
  display: flex;
  align-items: center;
  gap: 10px;
}
.todo-ant-drawer {
  /* max-width: 40% !important; */
  margin-left: auto !important;
  margin-right: auto !important;
}
.ant-drawer-content-wrapper {
  background: none;
  box-shadow: none !important;
  height: auto !important;
}
.todo-time-modal {
  width: 214px !important;
  height: 347px !important;
  position: relative;
}
.todo-time-modal .ant-modal-footer {
  bottom: 15px;
  position: absolute;
}
.todo-time-modal .ant-modal-content {
  height: 347px !important;
  padding: 20px 37px;
}

.todo-date-modal {
  width: 398px !important;
}

.todo-date-modal .ant-modal-footer {
  display: flex;
  justify-content: center;
}
.ant-popover-inner-content li:hover {
  background: #ebf5ff;
}
.ant-popover-inner-content li {
  display: flex;
  align-items: center;
  gap: 10px;
}
.ant-popover-inner-content li:active {
  background: #3594ff;
}
.todo-ant-drawer .create-task-title {
  font-size: 24px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.88);
}
.todo-ant-drawer .todo-title-card {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.todo-ant-drawer .ant-drawer-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ant-drawer-title .create-task-icons {
  display: flex;
  gap: 25px;
}
.todo-ant-drawer {
  border-radius: 8px 8px 0px 0px;
  padding: 12px;
  position: relative;
}
.ant-drawer-title .create-task-icons img {
  cursor: pointer;
  width: "20px";
}
.todo-ant-drawer .ant-drawer-header {
  border-bottom: none;
  padding-bottom: 10px;
}
.todo-ant-drawer .ant-drawer-body {
  padding-top: 0;
}
.todo-ant-drawer .ant-drawer-body .task-title {
  font-size: 16px;
}
.todo-ant-drawer .ant-drawer-body .task-desctiption {
  border: none;
  font-size: 16px;
  resize: none;
}
.todo-ant-drawer .ant-drawer-body .task-desctiption:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none;
}
.todo-ant-drawer .anticon-send {
  color: #0077d7;
  font-size: 22px;
}
.todo-ant-drawer .ant-drawer-footer {
  border-top: none;
}

.todo-ant-drawer .pinned-true {
  color: #0077d7;
}

.todo-ant-drawer .clock-true {
  color: #0077d7;
}

.todo-ant-drawer .flag-true {
  color: #0077d7;
}
.todo-menu-popover li {
  font-size: 16px;
  cursor: pointer;
  padding-bottom: 10px;
}
.todo-menu-popover img {
  width: 20px;
}
.todo-menu-popover {
  padding-top: 10px;
}
.todo-ant-drawer .subtask-label {
  font-size: 19px;
  font-weight: 500;
  color: #1e232cde;
}
.todo .anticon-down {
  width: 16px;
  margin-left: auto;
  margin-right: auto;
  color: #111111;
}
.todo .anticon-up {
  width: 16px;
  margin-left: auto;
  margin-right: auto;
  color: #111111;
}
.todo .icon-display {
  display: none;
}

@media only screen and (max-width: 768px) {
  .todo-ant-drawer {
    max-width: 100% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .ant-drawer-content-wrapper .task-drawer .ant-drawer-title div {
    font-size: 20px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .ant-drawer-content-wrapper .task-drawer .ant-drawer-close {
    display: none;
  }
  .taskset-container .taskset-icons .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
    gap: 7px;
    font-size: 16px;
  }
  .taskset-container .taskset-icons .ant-form-item-control-input-content img {
    max-width: 24px;
    min-width: 14px;
  }
  .taskset-container .taskset-icons .ant-form-item-control-input-content .pin-icon {
    font-size: 21px;
  }
  .taskset-container .taskset-icons .ant-form-item {
    margin-bottom: 0px;
  }
  .taskset-container .ant-btn {
    border: none;
    background: none;
    box-shadow: none;
    padding: 0;
    height: auto;
    display: flex;
  }
  .taskset-container .taskset-icons .mob-send-btn {
    max-width: 23px;
    width: 100%;
  }
  .task-drawer .create-task-title {
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    line-height: 30.1px;
    text-align: left;
    color: #1e232cde;
  }
}